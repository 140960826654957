<template>
  <div class="e-card e-rounding shadow-sm">
    <PremiumBanner/>
    <div class="row">

      <DateRangeFilter ref="dateFilter" @filter-changed="changedFilter"></DateRangeFilter>

      <div class="container">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s2" v-model="s2" @change="changedFilter()">
        <label class="custom-control-label" for="s2">{{ $t('excel.sheet.2') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s3" v-model="s3" @change="changedFilter()">
        <label class="custom-control-label" for="s3">{{ $t('excel.sheet.3') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s4" v-model="s4" @change="changedFilter()">
        <label class="custom-control-label" for="s4">{{ $t('excel.sheet.4') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s5" v-model="s5" @change="changedFilter()">
        <label class="custom-control-label" for="s5">{{ $t('excel.sheet.5') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s6" v-model="s6" @change="changedFilter()">
        <label class="custom-control-label" for="s6">{{ $t('excel.sheet.6') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s7" v-model="s7" @change="changedFilter()">
        <label class="custom-control-label" for="s7">{{ $t('excel.sheet.7') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s8" v-model="s8" @change="changedFilter()">
        <label class="custom-control-label" for="s8">{{ $t('excel.sheet.8') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s9" v-model="s9" @change="changedFilter()">
        <label class="custom-control-label" for="s9">{{ $t('excel.sheet.9') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s10" v-model="s10" @change="changedFilter()">
        <label class="custom-control-label" for="s10">{{ $t('excel.sheet.10') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s11" v-model="s11" @change="changedFilter()">
        <label class="custom-control-label" for="s11">{{ $t('excel.sheet.11') }}</label>
      </div>
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input" role="switch" id="s12" v-model="s12" @change="changedFilter()">
        <label class="custom-control-label" for="s12">{{ $t('excel.sheet.12') }}</label>
      </div>
      </div>

      <div class="container">
        <div class="row">
          <div class="col-sm">
            <Loader v-if="(exporting)"/>
          </div>
        </div>
      </div>

      <div class="ml-4">
        <div v-if="!showPremiumText" class="d-flex justify-content-end pt-2">
          <button v-show="!exportFinished && !exporting && rightExportSales" @click="exportReport()" class="btn btn-primary">{{ $t('button.exportExcel') }}</button>
          <a v-show="exportFinished" v-bind:href="bu+'/report/download-report-document?id='+exportDocumentId"
             class="btn btn-primary"><i class="fa fa-download pr-1"></i> {{ $t('button.exportSales') }}</a>
        </div>
        <div v-if="showPremiumText" style="color: darkred">{{ $t('report.premiumOnly')}}</div>
      </div>
    </div>


  </div>
</template>

<script>
import {baseURL} from '@/config/variables'
import PremiumBanner from "@/components/PremiumBanner";
import Loader from "@/components/Loader";
import DateRangeFilter from "@/components/util/DateRangeFilter.vue";
import rightUtil from "@/util/rights"

export default {
  name: 'ExportSales',
  components: {PremiumBanner,Loader,DateRangeFilter},
  data: function () {
    return {
      bu: baseURL,
      ak: localStorage.apiKey,
      exportDocumentId: null,
      exporting: false,
      exportFinished: false,
      showPremiumText: false,
      rightExportSales: false,
      s2: false,
      s3: false,
      s4: false,
      s5: false,
      s6: false,
      s7: false,
      s8: false,
      s9: false,
      s10: false,
      s11: false,
      s12: false,
    }
  },
  mounted() {
    this.rightExportSales = rightUtil.hasRight(rightUtil.codes.exportSales);
    this.readFromLocalStorage();
  },
  watch: {
    custom() {
      this.exportFinished = false;
    }
  },
  methods: {
    exportReport: function() {
      if(!this.exporting) {
        const vm = this;
        const ft = this.$refs.dateFilter;
        vm.exporting = true;
        this.$http.post('/report/create-report-document', {
          year: ft.selectedYear,
          type: ft.selectedType,
          quarter: ft.selectedQuarter,
          month: ft.selectedMonth,
          day: ft.selectedDay,
          week: ft.selectedWeek,
          custom: JSON.stringify(ft.custom),
          customTime: JSON.stringify(ft.customTime),
          useServerTime: ft.useServerTime,
          sheetFlags:vm.getSheetFlags()
        })
            .then(function (response) {
              vm.exporting = false;
              vm.exportFinished = true;
              vm.exportDocumentId = response.data.documentId;
            }, function () {
              vm.exporting = false;
              vm.exportFinished = false;
            });
      }
    },
    testPremium() {
      if((this.$refs.dateFilter.selectedType!=='day' || this.$refs.dateFilter.selectedDay!=='today') && localStorage.premium === 'false') {
        //not premium
        this.showPremiumText=true;
      } else {
        this.showPremiumText=false;
      }
    },
    changedFilter: function() {
      this.exportFinished = false;
      this.writeToLocalStorage();
    },
    getSheetFlags: function() {
      const vm = this;
      let res = 0;
      if(vm.s2===true) {res = res + (1<<1);}
      if(vm.s3===true) {res = res + (1<<2)}
      if(vm.s4===true) {res = res + (1<<3)}
      if(vm.s5===true) {res = res + (1<<4)}
      if(vm.s6===true) {res = res + (1<<5)}
      if(vm.s7===true) {res = res + (1<<6)}
      if(vm.s8===true) {res = res + (1<<7)}
      if(vm.s9===true) {res = res + (1<<8)}
      if(vm.s10===true) {res = res + (1<<9)}
      if(vm.s11===true) {res = res + (1<<10)}
      if(vm.s12===true) {res = res + (1<<11)}
      return res;
    },
    setSheetFlags: function(f) {
      const vm = this;
      vm.s2 = (f & (1<<1))>0;
      vm.s3 = (f & (1<<2))>0;
      vm.s4 = (f & (1<<3))>0;
      vm.s5 = (f & (1<<4))>0;
      vm.s6 = (f & (1<<5))>0;
      vm.s7 = (f & (1<<6))>0;
      vm.s8 = (f & (1<<7))>0;
      vm.s9 = (f & (1<<8))>0;
      vm.s10 = (f & (1<<9))>0;
      vm.s11 = (f & (1<<10))>0;
      vm.s12 = (f & (1<<11))>0;
    },
    readFromLocalStorage: function() {
      const vm = this;
      let val = localStorage.getItem('exportSheets');
      if(val === null) {
        val = 8191; //all bits combinations
      }
      vm.setSheetFlags(val);
    },
    writeToLocalStorage: function () {
      const vm = this;
      localStorage.setItem('exportSheets',vm.getSheetFlags());
    }
  }
}
</script>

<style>
.report-info {
  background-color: #97bdce;
  padding: 0.5rem;
}
</style>